import React from "react"
import { graphql } from "gatsby"
import api from "../lib/api"
import { messages } from "../lib/messages"
import moment from "moment"
import Layout from "../components/layout"
import Header from "../components/header"
import PageTitle from "../components/page-title"
import { TextToggle } from "../components/toggles"
import { CustomWorkForm } from "../components/forms"
import Icon from "../components/icon"
import { BlackButton } from "../components/black-buttons"
import _sortBy from "lodash/sortBy";

class CustomWork extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			category: 'choose',
			quantity: 0,
			description: '',
			name: '',
			email: '',
			phone: '',
		}
		this.emailRegex = /^([A-Za-z0-9_\-.+])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
	}
	updateField = (event) => {
		const new_value = event.target.value;
		this.setState({ [event.target.name]: new_value });
	}
	getEstimate = () => {
		let today = moment();
		let estimate = today.add(3, 'weeks');
		const sunday = estimate.weekday() === 0;
		if(sunday) {
			estimate.add(1, 'days');
		}
		return estimate.format('dddd, MMMM D, YYYY')
	}
	prepCategories = () => {
		let categories = _sortBy(this.props.data.categories.edges.map(({node}) => node), 'name');
		const mapped = categories.map(category => {
			return { label: category.name, value: category.slug };
		});
		mapped.unshift({ label: 'Choose a Category', value: 'choose'});
		// mapped.push({ label: 'Other', value: 'Other Kind of Product'});
		return mapped;
	}
	emailValid = () => {
		return this.emailRegex.test(this.state.email);
	}
	valid = () => {
		const s = this.state;
		return s.quantity > 0 && s.description.length > 2 && s.name.length > 2 && this.emailValid() && s.phone.length > 5 && s.category !== 'choose';
	}
	submit = async () => {
		try {
			let req = { ...this.state };
			req.earliest_delivery = this.getEstimate();
			const response = await api.sendRequest({ request: req });
			if(response.error) {
				messages.show('REQUEST_FAILED');
				return;
			}
			messages.show('REQUEST_SENT');
		} catch(e) {
			messages.show('REQUEST_FAILED');
		}
	}
	render() {
		return (
			<Layout>
				<div className='ph3 ph4-ns pt3 pt0-ns pb3 pb5-ns'>
					<PageTitle title="Custom Work" />

					<div className='w-100 cf'>

						<div className='w-100 w-50-ns fl pr5-ns'>
							<p className='ma0 pa0 black-90 f4 f3-ns'>Let's do it your way.</p>
							<p className='f5 mt4 black-90 lh-copy'>Every detail matters. For your special day, 610 Design Studio can design a one-of-a-kind piece. Tell us what you like, how many you need, and when you need it by, and we'll take care of the rest.</p>
							<p className='f5 mt4 black-90 lh-copy'><span className='b'>What happens after you submit a request?</span> We'll contact you within 1 business day to finalize the details of your request. Once that's done, we'll follow up with a custom design that fits your description. You'll have a chance to review it and make a revision. With your revision incorporated, we'll finalize the design, collect payment, and ship your product straight to your door.</p>
							<div className='mt4 mt5-ns'>
								<p className='ttu tracked black-50 f6 fw4'>More Information</p>
								<TextToggle title="Material Choices">We work with a wide variety of materials. Natural wood and acrylic are our most popular choices, but we can also cut leather and paper. Our engraving options are nearly limitless: metal, stone, marble, slate, cork, and many more.</TextToggle>
							</div>
							<div className='mt3 mt4-ns'>
								<TextToggle title="Color Options">We're partial to the classic colors: white, black, rose gold, gold and silver. If your special event has a specific color theme, however, we can supply almost any color imaginable. </TextToggle>
							</div>
						</div>

						<div className={'w-100 w-50-ns mv5 mv0-ns fl '}>
							<div className={'ba b--black-20 '}>
								<CustomWorkForm data={ this.state } categories={ this.prepCategories() } onChange={ this.updateField } />
							</div>

							<div className='tr pt4'>
								<BlackButton title="Submit Request" onClick={ this.submit } disabled={ !this.valid() } />
							</div>

							<div className='bl bw2 bw3-ns b--black-80 pl3 pl4-ns mt4 mt5-ns pt4 pv3 dn'>
								<Icon className='db relative mb3 anim-in-slow' icon="alert-circle" style={{ width: 32, height: 32 }} />
								<p className='ma0 pa0 lh-copy black-90'>The average design and production time for custom work is <span className='b'>2 weeks</span>. Between this and shipping time, the earliest date your custom order can arrive is <span className='b'>{ this.getEstimate() }.</span></p>
							</div>
						</div>

					</div>
				</div>
			</Layout>
		)
	}
}


 export default CustomWork;


 export const query = graphql`
 	query CustomWorkQuery {
 		categories: allAirtableCategories {
 			totalCount
 			edges {
 				node {
 					name
 					slug
 				}
 	  		}
 		}
   }
 `